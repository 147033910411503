export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17')
];

export const server_loads = [];

export const dictionary = {
		"/": [3],
		"/auth/google-oauth-success-redirect": [4],
		"/complete-registration": [5],
		"/dashboard": [6,[2]],
		"/dashboard/copy-trade": [7,[2]],
		"/dashboard/intro": [8,[2]],
		"/dashboard/positions": [9,[2]],
		"/dashboard/referral-dashboard": [10,[2]],
		"/dashboard/report-a-bug": [11,[2]],
		"/dashboard/request-a-feature": [12,[2]],
		"/dashboard/sniper": [13,[2]],
		"/dashboard/trade": [14,[2]],
		"/dashboard/wallets": [15,[2]],
		"/login": [16],
		"/signup": [17]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';